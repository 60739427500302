<template>
 <div style="background-color: #e7e8e959; min-height:100vh;overflow:hidden auto" class="w-100">
 <div class="drea-navbar">
   <b-navbar toggleable="lg" type="light" variant="white" class="px-md-5" sticky>
    <b-navbar-brand href="#" class="pl-4"><img style="height: 40px;width:auto" src="/img/brand_icon.png"></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" class="border-0">
       <template>
        <img src="/img/menu.png" />
      </template>
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto" style="align-items: center;" >
       
        <b-nav-item-dropdown class="px-5 " >
           <template #button-content>
            <img src="/img/flags/Nigeria_flag.png"><span>&nbsp;&nbsp;NG</span>
          </template>
          <b-dropdown-item href="#"> 
          <img src="/img/flags/senegal_flag.png" style="width:25px;height:20px;border-radius:3px"><span>&nbsp;&nbsp;SE</span></b-dropdown-item>
          <b-dropdown-item href="#"><hr/></b-dropdown-item>
          <b-dropdown-item href="#"><img src="/img/flags/Ghana_flag.png" style="width:25px;height:20px;border-radius:3px"><span>&nbsp;&nbsp;GH</span></b-dropdown-item>
          <b-dropdown-item href="#"><hr/></b-dropdown-item>
          <b-dropdown-item href="#"><img src="/img/flags/southAfrica_flag.png" style="width:25px;height:20px;border-radius:3px"><span>&nbsp;&nbsp;SA</span></b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <span>{{ clients_type }}</span>
          </template>
          <b-dropdown-item @click="page('customers')"  href="/">Customers</b-dropdown-item>
          <b-dropdown-item ><hr/></b-dropdown-item>
          <b-dropdown-item @click="page('partners')"  href="/partners">Partners</b-dropdown-item>
          <b-dropdown-item  ><hr/></b-dropdown-item>
          <b-dropdown-item @click="page('riders')" href="/riders" >Riders</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item class="px-5">
          <div class="default_buttonStyle">Download</div>
        </b-nav-item>
      </b-navbar-nav>
     </b-collapse>
    </b-navbar>
    </div>
    <router-view></router-view>

    <!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content p-4 text-center" style="border-radius:15px;">
      <div class="my-3" style="font-size:14px;font-weight:500px">Get the Dreacot app</div>
        <img class="mx-auto" id="app_modal_qrcode" src="/img/dreacotuserapp.png" style="width:150px;height:auto"/>
        <div class="px-3 medium text-center" style="    font-size: smaller;font-weight: 500;">Scan the qrcode with your phone camera <br/>to download the Dreacot app. </div>
    </div>
  </div>
</div>
    <div class="" style="background-color:#000000;color:#fff">
      <div class="px-md-5 px-3 mx-md-4 pt-5 ">
       <div class="row mt-4">
        <div class="col">
          <div class="row">
           <div class="d-flex flex-column col-md-3" style="font-weight: 200;">
            <b class="mb-3" style="font-weight:700">Company</b>
            <a href="/about_us"> <span>About us</span></a>
              <span>Careers</span>
               <span>Become a partner</span>
           </div>
           <div class="d-flex flex-column col-md-3" style="font-weight: 200;">
            <b class="mb-3" style="font-weight:700">Support</b>
             <span>Contact us</span>
              <span>FAQS</span>
               <span>Partner support</span>
           </div>
            <div class="d-flex flex-column col-md-3" style="font-weight: 200;">
            <b class="mb-3" style="font-weight:700">Help</b>
             <span>Report a problem</span>
              <span>Help center</span>
               
           </div>
            <div class="d-flex flex-column col-md-3" style="font-weight: 200;">
            <b class="mb-3" style="font-weight:700">Trust and Legal</b>
             <span>Privacy Policy</span>
              <span>Terms & Conditions</span>
               <span>Cookie Policy</span>
           </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
         <div class="d-flex flex-column">
          <div class="text-right mb-5">
             <img src="img/brand_icon_white.png" style="width:141.25px;height:50px" />
          </div>
          <div class="text-right mt-3 pt-3" style="font-weight: 200;">
           <div class="col-5 mb-3" style="border-top:solid grey 1px;margin-left:55%"></div>
           <div>+234 909-381-4456</div>
            <div>support@dreacot.com</div>
             <div>123 Dreacot Street, Lagos, Nigeria</div>
          </div>
         </div>
        </div>
       </div>
     <!-- End of menu row -->
        <div class="d-flex justify-content-between py-4">
         <span class="mt-4" style="font-size: 12px;">© 2024 — Copyright</span>
         <img src="/img/scrollToTop.png" @click="scrollToTopf()"/>
        </div>
      </div>
    </div>
 </div>
 
</template>
<script>
export default {
   data(){
     return{
      clients_type:'Customers'
     }
   },
  methods: {
   navigat(string){
    window.location = string
   },
   scrollToTopf(){
    scrollToTop()
   },
   page(string){
     window.sessionStorage.setItem('pgg',string)
   },
   appshareQRcode(btnType){
     var page = window.sessionStorage.getItem('pgg')
     if(btnType =='ios'&& page=='customers'){
      return '/img/dreacotuserapp.png'
     }else if(btnType =='ios' && page=='partners'){
       return '/img/partner_app.png'
     }else if(btnType =='ios' && page=='riders'){
       return '/img/rider_app.png'
     }else if(btnType =='android'&& page=='customers'){
      return '/img/dreacotuserapp.png'
     }else if(btnType =='android' && page=='partners'){
       return '/img/partner_app.png'
     }else if(btnType =='android' && page=='riders'){
       return '/img/rider_app.png'
     }else{
      if(btnType =='ios'){
        return '/img/dreacotuserapp.png'
      }else if(btnType =='ios'){
        return '/img/partner_app.png'
      }
     }
   },
   getPage(){
    return window.sessionStorage.getItem('pgg') == null?'customers': window.sessionStorage.getItem('pgg')
   }
  },
  mounted(){
   var that = this
   this.getPage().toLowerCase()=='customers'?this.clients_type='Customers':
   this.getPage().toLowerCase()=='partners'?this.clients_type='Partners':this.clients_type='Riders'?this.clients_type='Riders': this.clients_type='Customers'
   document.querySelectorAll('.modal_download').forEach((e)=>{
    e.onclick = function(){
    console.log(e.dataset.type)
    document.getElementById('app_modal_qrcode').src = that.appshareQRcode(e.dataset.type)
    $('#exampleModalCenter').modal('show')
   }
   })
  }
 }


 
 function scrollToTop() {
  // Get the current scroll position
  const start = window.pageYOffset;
  
  // Set the scroll distance for the animation
  const distance = start;
  
  // Set the duration of the scroll animation (in milliseconds)
  const duration = 1500;
  
  // Start time of the animation
  let startTime;

  // Animation function to scroll to the top
  function animateScroll(timestamp) {
    if (!startTime) startTime = timestamp;
    const progress = timestamp - startTime;
    const currentScroll = easeInOutCubic(progress, start, -start, duration);
    
    // Set the window's scroll position
    window.scrollTo(0, currentScroll);
    
    // Continue the animation until the duration is met
    if (progress < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      window.scrollTo(0, 0);  // Ensure it reaches the top exactly
    }
  }

  // Easing function for a smooth animation
  function easeInOutCubic(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t * t + b;
    t -= 2;
    return (c / 2) * (t * t * t + 2) + b;
  }

  // Start the animation
  requestAnimationFrame(animateScroll);
}

// Call the function to trigger scroll-to-top
scrollToTop();
</script>
<style>
a{
 color :inherit !important
}
.dark{

}
.default_buttonStyle{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 13.5px 24px;
gap: 10px;
width: 160px;
height: 48px;
background: #000000;
/* Shadow effect/16Dp Penumbra */
box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
border-radius: 8px;
color:#fff;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;

}
.navbar-light .navbar-nav .nav-link {
    color: #000;
}
.default_background_color{
 background-color: #e7e8e959;
}

.integration_image{
  margin-top: -50px;
}
.default_background_color{
 background-color: #e7e8e959;
}
 .section1_image{
   width:100%;
   height : 1050px;
   background-image: url('/public/img/home_image1.jpg');
   background-repeat: no-repeat;
   background-position: center;
   background-size: 100% 100%;
   position:relative;

 }
 .section7_image{
   width:100%;
   height :906px;
   background-image: url('/public/img/home_image2.jpg');
   background-repeat: no-repeat;
   background-position: center;
   background-size: 100% 115%;
   position:relative;

 }
 .dark_overlay{
  width: inherit;height:inherit;background-color:#00000066;
  position:absolute;top:0;z-index: 1;
 }

.section1_header_text{

width: 682px;
height: 144px;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 72px;
text-align: center;

color: #FFF;
 }

 .section1_subheader_text{
    font-style: normal;font-weight: 400;font-size: 20px;line-height: 36px;
 }

.section2{
padding: 80px 120px;
gap: 40px;
background-color: #FFF;


}
 .section2_header_text{

font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 120%;
/* or 38px */
text-align: center;
color: #1C1B1F;
 }
 .section3{
  background-color: #e7e8e959;
 }

 @media(max-width:600px ) {
.section1_image{
   
   background-position: right;
 }
.section1_image .d-flex img{
 width:100%;
}
.section2{
padding: 10px;
padding-bottom: 40px;
gap: 40px;
background-color: #FFF;


}
.section1_header_text{
width:80%;
height: auto;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 30px;
text-align: center;

color: #FFF;
 }

 .section1_subheader_text{
    font-style: normal;font-weight: 400;font-size: 12px;line-height: 18px;
 }

 .section2_header_text{
  font-size: 20px;
 }
 .section2_subheader_text{
    font-style: normal;font-weight: 400;font-size: 12px;line-height: 18px;
 }
 }
 .integration_image{
  margin-top: 0px;
  width:70%;
  position:relative;
  top: 55px;
  animation: spin 40s infinite linear;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>